import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { useSearch } from "../Search/UseSearch";
import itemsVidJson from "../../db/fondo-pantallaImg.json";
import Pagination from "../Pagination/Pagination";
import Modal from "../Modal/Modal";
import Pie from "../Pie/Pie";

const SeriesImagenes = () => {
  const [isActive, setIsActive] = useState(false);
  const [altxt, setAltTxt] = useState('');
  const [seoName, setSeoName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const openModal = (url, type, name) => {
    setAltTxt(altxt);
    setSeoName(name);
    setVideoUrl(url);
    setIsActive(true);
    setMediaType(type);
  };
  const closeModal = () => {
    setIsActive(false);
  };//Termina MOdal
  const itemsVid = itemsVidJson.results.filter((item) => item.catego === "series");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const [searchResults, handleSearch] = useSearch(itemsVid, "name");

  const indexOfLastItem = Math.min(currentPage * itemsPerPage, searchResults.length);
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <><Helmet>
        <title>Fondos de pantalla para celular de series gratis</title>
        <meta name="description" content="Increibles fondos de pantalla para celular de series de tv. fondos para celular de series.fondos para celular de last of us. fondos para celular de breaking bad. "/>
        <meta name="content" content="4k wallpaper para celular Increibles fondos de pantalla para teléfonos inteligentes. fondos de pantalla para celular gratis. wallpapers de series" />
        <meta name="keywords" content="fondo de pantalla para celular HD last of us walking dead breaking bad"/>
    </Helmet><div className="columns mosaico">
      <div className="column is-1"></div>
      <div className="column is-10">
        <div className="columns contContol field">
          <h1 className="tituloBarra">
            <em>Fondos de pantalla de Series de Tv</em>
          </h1>
          <p className="control has-icons-left has-icons-right">
            <input className="input" type="text" placeholder="Buscar" onChange={handleSearch} />
            <span className="icon is-small is-left">
              <img src="../../img/search.svg" className="logoApp is-flex is-centered" alt="Search Icon" />
            </span>
          </p>
        </div>
        <div className="columns is-mobile is-multiline">
          {currentItems.map((item) => (
            <div key={item.id} className="column is-4-mobile is-2-desktop">
              <div className="card">
                <figure class="image is-1by2">
                  <img src={item.image} alt={item.altxt} />
                </figure>
                <div className="card-content is-flex is-centered">
                  <button onClick={() => openModal(item.image, 'imagen')} id="showModal" className="button is-danger is-rounded btnCarrusel">
                    Ver mas
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={searchResults.length} paginate={paginate} />
      </div>
      {isActive && (
        <Modal isActive={isActive} closeModal={closeModal} videoUrl={videoUrl} mediaType={mediaType} seoName={seoName}/>
      )}
    </div><Pie /></>
  );
};

export default SeriesImagenes;
