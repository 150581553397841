import React from 'react'

const Pie = () => {
  return (
    <footer>
    <div className="pata columns is-mobile">
      <div className="column is-1"></div>
      <div className="column is-1"></div>
      <div className="column is-10">
      <a href="mailto:smrtback2@gmail.com">
          <img className="icnPie" src="../../../img/email.svg" alt=""/>
      </a> 
      <a href='https://www.tiktok.com/@smartbackground.wtf'   target='_blank'>
          <img className="icnPie" src="../../../img/tt.svg" alt=""/>
      </a>
      <a href="https://www.instagram.com/sbg.wtf/"  target='_blank'>
          <img className="icnPie" src="../../../img/ig.svg" alt=""/>  
      </a>
      <a href='https://www.youtube.com/@smartbackgrounds-nn8du/shorts'   target='_blank'>
          <img className="icnPie" src="../../../img/yt.svg" alt=""/>
      </a>
      </div>
    </div>
</footer>

  )
}

export default Pie