import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
      const timeout = setTimeout(() => {
        navigate('/');
      }, 1000); // Redirect after 3 seconds
  
      return () => clearTimeout(timeout);
    }, [navigate]);

  return (
    <div class="columns is-flex is-centered is-vcentered">
        <figure class="image notfound">
        <img src="../../img/notfound.jpg" className="is-horizontal-center" alt='fondos de pantalla para celular' />
        </figure>
    </div>
    
  );
};

export default NotFound;
