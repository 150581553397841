import React from 'react'

function HeroHome() {
  return (
    <section class="hero">
        <div class="hero-body is-flex is-horizontal-center p-1 pb-5 columns valigned">
              <div className='column is-1-mobile'></div>
              <div className='column is-7-mobile'>
                <h1 className='tituloBarraBlack'>
                  <span class="title-word title-word-1">!Dale vida a tu </span>
                  <span class="title-word title-word-2">celular </span>
                  <span class="title-word title-word-3">con nuestros increibles </span>
                  <span class="title-word title-word-4">fondos de pantalla!</span>
                </h1>
              </div>
              <div className='column is-3-mobile'> 
                <img class="home" src="../img/home.jpg" alt="Fondos de pantalla animados para celular"/>
              </div>  
              <div className='column is-1-mobile'></div>
        </div>
    </section>
  )
}

export default HeroHome