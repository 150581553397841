import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { useSearch } from "../Search/UseSearch";
import itemsVidJson from "../../db/fondo-pantallaImg.json";
import Pagination from "../Pagination/Pagination";
import Modal from "../Modal/Modal";
import Pie from "../Pie/Pie";

export default function FrasesMoticacionImagenes () {
  const [isActive, setIsActive] = useState(false);
  const [altxt, setAltTxt] = useState('');
  const [seoName, setSeoName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const openModal = (url, type, name) => {
    setAltTxt(altxt);
    setSeoName(name);
    setVideoUrl(url);
    setIsActive(true);
    setMediaType(type);
  };
  const closeModal = () => {
    setIsActive(false);
  } //Termina Modal
  const itemsVid = itemsVidJson.results;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const [searchResults, handleSearch] = useSearch(itemsVid, "name");

  const filteredItemsByCategory = searchResults.filter(
    (item) => item.catego === "motivacion"
  );

  const indexOfLastItem = Math.min(
    currentPage * itemsPerPage,
    filteredItemsByCategory.length
  );
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItemsByCategory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <><Helmet>
    <title>Fondos de pantalla para celular motivacion frases superacion personal</title>
    <meta name="description" content="Increibles fondos de pantalla para celular motivación. fondos para celular frases motivación.fondos para mantenerte motivado. fondos para celular para motivarte a ir al gimnasio"/>
    <meta name="content" content="4k wallpaper para celular Increibles fondos de pantalla para teléfonos inteligentes. fondos de pantalla para celular gratis. wallpapers de superacion personal" />
    <meta name="keywords" content="fondo de pantalla para celular HD Frases motivadoras superacion personal estoico"/>
</Helmet>
      <div className="columns mosaico">
        <div className="column is-1"></div>
        <div className="column is-10">
          <div className="columns contContol field">
              <h1 className="tituloBarra"><em>Fondos de pantalla de Motivación</em></h1>
            <p className="control has-icons-left has-icons-right">
              <input
                className="input"
                type="text"
                placeholder="Buscar"
                onChange={handleSearch}
              />
              <span className="icon is-small is-left">
                <img
                  src="../../img/search.svg"
                  className="logoApp is-flex is-centered"
                  alt="search-icon"
                />
              </span>
            </p>
          </div>
          <div className="columns is-mobile is-multiline">
            {currentItems.map((item) => (
              <div key={item.id} className="column is-4-mobile is-2-desktop">
                <div className="card">
                  <figure class="image is-1by2">
                    <img src={item.image} alt={item.altxt} />
                  </figure>
                  <div className="card-content is-flex  is-centered">
                    <button
                      onClick={() =>
                        openModal(item.image, "imagen", item.name)
                      }
                      id="showModal"
                      className="button is-danger is-rounded btnCarrusel"
                    >
                      Ver mas
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredItemsByCategory.length}
            paginate={paginate}
          />
        </div>
      {isActive && (
        <Modal isActive={isActive} closeModal={closeModal} videoUrl={videoUrl} mediaType={mediaType} seoName={seoName} />
      )}
    </div><Pie /></>
  );
};


