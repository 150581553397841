import React, { useState } from "react";

const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (event, page) => {
    event.preventDefault();
    setCurrentPage(page);
    paginate(page);
  };

  const next = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= pageNumbers.length) {
      setCurrentPage(nextPage);
      paginate(nextPage);
    }
  };

  const prev = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      setCurrentPage(prevPage);
      paginate(prevPage);
    }
  };

  return (

    <nav  className="pagination is-centered" role="navigation" aria-label="pagination">
        <button onClick={prev} className="pagination-previous" disabled={currentPage === 1}>
          Anterior
        </button>
        <ul className="pagination-list">
          {pageNumbers.map((page) => (
            <li key={page} >
              <a 
                href="javascript:void(0)"
                className={currentPage === page ? "pagination-link is-current" : "page-item"}
                aria-label={`Go to page ${page}`}
                onClick={(event) => handleClick(event, page)}
              >
                {page}
              </a>
            </li>
          ))}
        </ul>
        <button
          onClick={next}
          className="pagination-next"
          disabled={currentPage === pageNumbers.length}
        >
          Siguiente
        </button>
      </nav>
  );
};

export default Pagination;
