import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import clasVidData from '../../db/fondo-pantalla.json';
import clasImgData from '../../db/fondo-pantallaImg.json';

export default function Navbar() {
  
  const clasificacionVid = clasVidData.info.clasificacionVideos;
  const clasificacionImg = clasImgData.info.clasificacionImg;
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const toggleBurger = () => {
    setIsBurgerActive(!isBurgerActive);
  };
  const toggleDropdown = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };

  return (

    <header className="bd-example is-paddingless">
      <nav className="navbar is-dark">
        <div className="navbar-brand">
          <div className="logo">
          <NavLink to="/" >
              <img src="../../img/logo.svg" className="logoApp is-flex is-centered" alt='fondos de pantalla para celular' />
          </NavLink> 
          </div>
          <div
            className={`navbar-burger ${isBurgerActive ? 'is-active' : ''}`}
            onClick={toggleBurger}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={`navbar-menu ${isBurgerActive ? 'is-active' : ''}`}>
          <div className="navbar-end">
          <div className="navbar-item ">
          <NavLink to="/" ><a className="navbar-link is-arrowless" >Inicio</a></NavLink>
              
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link" >
                Fondos de pantalla con movimiento
              </a>
              <div className="navbar-dropdown">
              <NavLink to="/anime" ><a className="tag is-primary item" >Anime</a></NavLink>    
                <NavLink to="/animales" ><a className="tag is-primary item" >Animales</a></NavLink>           
                <NavLink to="/automoviles" ><a className="tag is-primary item" >automóviles</a></NavLink> 
                <NavLink to="/cartoons" ><a className="tag is-primary item" >Cartoons</a></NavLink>  
                <NavLink to="/comics" ><a className="tag is-primary item" >Comics</a></NavLink> 
                <NavLink to="/Cute_&_Adorable" ><a className="tag is-primary item" >Cute & adorable</a></NavLink> 
                <NavLink to="/dinosaurios" ><a className="tag is-primary item" >Dinosaurios</a></NavLink>
                <NavLink to="/deportes" ><a className="tag is-primary item" >Gym & Deportes</a></NavLink>
                <NavLink to="/peliculas" ><a className="tag is-primary item" >Películas</a></NavLink> 
                <NavLink to="/memes" ><a className="tag is-primary item" >Memes</a></NavLink>  
                <NavLink to="/musica" ><a className="tag is-primary item" >Música</a></NavLink>   
                <NavLink to="/milicia" ><a className="tag is-primary item" >Militar</a></NavLink>
                <NavLink to="/mexico" ><a className="tag is-primary item" >México</a></NavLink>
                <NavLink to="/motivacion" ><a className="tag is-primary item" >Motivación</a></NavLink>  
                <NavLink to="/series" ><a className="tag is-primary item" >Series</a></NavLink>  
                <NavLink to="/sexy" ><a className="tag is-primary item" >Sexy</a></NavLink>  
                <NavLink to="/lugareshermosos" ><a className="tag is-primary item" >Lugares hermosos</a></NavLink>  
                <NavLink to="/videogames" ><a className="tag is-primary item" >video games</a></NavLink>             
                <NavLink to="/todos" ><a className="tag is-info item" >Todas los videos</a></NavLink> 
              </div>
            </div>
            <div className="navbar-item has-dropdown is-toggable  is-hoverable">
              <a className="navbar-link" >
                Fondos de pantalla imágenes
              </a>
              <div className="navbar-dropdown">
                <NavLink to="/AnimeImagenes" ><a className="tag is-info item" >Anime</a></NavLink>    
                <NavLink to="/AnimalesImagenes" ><a className="tag is-info item" >Animales</a></NavLink>           
                <NavLink to="/imagenescarros" ><a className="tag is-info item" >automóviles</a></NavLink> 
                <NavLink to="/imagenescartoons" ><a className="tag is-info item" >Cartoons</a></NavLink>  
                <NavLink to="/imagenescomics" ><a className="tag is-info item" >Comics</a></NavLink> 
                <NavLink to="/imagenescute" ><a className="tag is-info item" >Cute & adorable</a></NavLink> 
                <NavLink to="/imagenesdinosaurios" ><a className="tag is-info item" >Dinosaurios</a></NavLink>
                <NavLink to="/imagenesdeportes" ><a className="tag is-info item" >Gym & Deportes</a></NavLink>
                <NavLink to="/imagenespeliculas" ><a className="tag is-info item" >Películas</a></NavLink> 
                <NavLink to="/imagenesmemes" ><a className="tag is-info item" >Memes</a></NavLink>  
                <NavLink to="/imagenesmusica" ><a className="tag is-info item" >Música</a></NavLink>   
                <NavLink to="/imagenesmilicia" ><a className="tag is-info item" >Militar</a></NavLink>
                <NavLink to="/imagenesmexico" ><a className="tag is-info item" >México</a></NavLink>
                <NavLink to="/imagenesmotivacion" ><a className="tag is-info item" >Motivación</a></NavLink>  
                <NavLink to="/imagenesseries" ><a className="tag is-info item" >Series</a></NavLink>  
                <NavLink to="/imagenessexys" ><a className="tag is-info item" >Sexy</a></NavLink>  
                <NavLink to="/imagenesdelugareshermosos" ><a className="tag is-info item" >Lugares hermosos</a></NavLink> 
                <NavLink to="/imagenesvideogames" ><a className="tag is-info item" >video games</a></NavLink>            
                <NavLink to="/todaslasimagenes" ><a className="tag is-primary item" >Todas las imagenes</a></NavLink>      
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
