import { useState, useEffect } from "react";

export function useSearch(items, searchKey) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(items);

  useEffect(() => {
    const filteredItems = items.filter((item) =>
      item[searchKey].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredItems);
  }, [items, searchTerm, searchKey]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  return [searchResults, handleSearch];
}
