import { Helmet } from 'react-helmet';

const Modal = ({ isActive, closeModal, videoUrl, mediaType,seoName}) => {
  const staticText = "fondo de pantalla HD de";
  const smartbanckground = "smartbanckground";
  return (
    <><Helmet>
    <title>smartbackgrounds.wtf | Fondos de pantalla para smartphones de animales hd</title>
    <meta name="content" content={`${staticText} ${seoName}`} />
    <meta name="keywords" content={`${staticText} ${seoName}`}/>
    </Helmet>
        <div id="image-modal" className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Descargalo</p>
                <button id="image-modal-close" className="button " onClick={closeModal}>Cerrar</button>
                <button id="image-modal-close" className="button modal-close" onClick={closeModal}>Cerrar</button>
              </header>
              <section className="modal-card-body">
                {mediaType === 'video' ? (
                  <video autoPlay playsInline muted loop>
                    <source src={videoUrl} type="video/mp4" alt={`${staticText} ${seoName}`}   />
                  </video>
                ) : (
                  <figure class="image is-1by2">
                    <img src={videoUrl} alt={`${staticText} ${seoName}`} />
                  </figure>
                )}

                <div className="columns">
                  <div className="column"></div>
                  <div className="column">
                    <a
                      target="_self"
                      className="is-flex is-centered button is-small is-black is-rounded btnHome"
                      download={smartbanckground + seoName} 
                      href={videoUrl}
                    >
                      <img
                        src="../img/dwnld.svg"
                        alt="Placeholder"
                      />
                      <p className="pBTN">Descargar</p>
                    </a>
                  </div>
                  <div className="column"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
    </>
  );
};

export default Modal;