import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { useSearch } from "../Search/UseSearch";
import itemsVidJson from "../../db/fondo-pantalla.json";
import Pagination from "../Pagination/Pagination";
import Modal from "../Modal/Modal";
import Pie from "../Pie/Pie";

export default function Sexy () {
  const [isActive, setIsActive] = useState(false);
  const [seoName, setSeoName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const openModal = (url, type, name) => {
    setSeoName(name);
    setVideoUrl(url);
    setIsActive(true);
    setMediaType(type);
  };
  const closeModal = () => {
    setIsActive(false);
  } //Termina Modal
  const itemsVid = itemsVidJson.resultsVid;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const [searchResults, handleSearch] = useSearch(itemsVid, "name");

  const filteredItemsByCategory = searchResults.filter(
    (item) => item.catego === "sexy"
  );

  const indexOfLastItem = Math.min(
    currentPage * itemsPerPage,
    filteredItemsByCategory.length
  );
  //const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfFirstItem = 0 - itemsPerPage;
  const currentItems = filteredItemsByCategory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <><Helmet>
      <title>Fondos de pantalla para celular chicas sexys gratis livewallpaper</title>
      <meta name="description" content="Increibles fondos de pantalla para celular de chicas sexys. fondos animados para celular de chicas hermosas.fondos animados para celular de chicas en bikini. fondos animados para celular de mujeres en la playa."/>
      <meta name="content" content="4k wallpaper para celular Increibles fondos de pantalla de chicas guapas para teléfonos inteligentes. fondos de pantalla con movimiento para celular gratis. wallpapers de buenotas" />
      <meta name="keywords" content="fondo de pantalla para celular HD nalgas fit mujeres hermosas livewallpaper"/>
  </Helmet>
      <div className="columns mosaico">
        <div className="column is-1"></div>
        <div className="column is-10">
          <div className="columns contContol field">
            <h1 className="tituloBarra">
              <em>Fondos de pantalla con movimiento super sexys</em>
            </h1>
            <p className="control has-icons-left has-icons-right">
              <input
                className="input"
                type="text"
                placeholder="Buscar"
                onChange={handleSearch}
              />
              <span className="icon is-small is-left">
                <img
                  src="../../img/search.svg"
                  className="logoApp is-flex is-centered"
                  alt="search-icon"
                />
              </span>
            </p>
          </div>
          <div className="columns is-mobile is-multiline">
            {currentItems.map((item) => (
              <div key={item.id} className="column is-4-mobile is-2-desktop">
                <div className="card">
                  <video autoPlay playsInline muted loop>
                    <source src={item.image} alt={item.altxt} poster={item.poster} type="video/mp4" />
                  </video>
                  <div className="card-content is-flex  is-centered">
                    <button
                      onClick={() =>
                        openModal(item.image, "video", item.name)
                      }
                      id="showModal"
                      className="button is-danger is-rounded btnCarrusel"
                    >
                      Ver mas
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredItemsByCategory.length}
            paginate={paginate}
          />
        </div>
      {isActive && (
        <Modal isActive={isActive} closeModal={closeModal} videoUrl={videoUrl} mediaType={mediaType} seoName={seoName} />
      )}
    </div><Pie /></>
  );
};


