import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Modal from "./Modal/Modal";



function makeCarouselDraggable(carousel) {

  let isDown = false;
  let startX;
  let scrollLeft;

  carousel.addEventListener('mousedown', handleMouseDown);
  carousel.addEventListener('touchstart', handleTouchStart);

  carousel.addEventListener('mouseleave', handleMouseLeave);
  carousel.addEventListener('touchcancel', handleTouchCancel);

  carousel.addEventListener('mouseup', handleMouseUp);
  carousel.addEventListener('touchend', handleTouchEnd);

  carousel.addEventListener('mousemove', handleMouseMove);
  carousel.addEventListener('touchmove', handleTouchMove);

  function handleMouseDown(e) {
    isDown = true;
    startX = e.pageX - carousel.offsetLeft;
    scrollLeft = carousel.scrollLeft;
  }

  function handleTouchStart(e) {
    isDown = true;
    startX = e.touches[0].pageX - carousel.offsetLeft;
    scrollLeft = carousel.scrollLeft;
  }

  function handleMouseLeave() {
    isDown = false;
  }

  function handleTouchCancel() {
    isDown = false;
  }

  function handleMouseUp() {
    isDown = false;
  }

  function handleTouchEnd() {
    isDown = false;
  }

  function handleMouseMove(e) {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - carousel.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjust scrolling speed here
    carousel.scrollLeft = scrollLeft - walk;
  }

  function handleTouchMove(e) {
    if (!isDown) return;
    e.preventDefault();
    const x = e.touches[0].pageX - carousel.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjust scrolling speed here
    carousel.scrollLeft = scrollLeft - walk;
  }
}

const FetchData = ({ items, itemsVid, currentPage, itemsPerPage }) => {  
  const [isActive, setIsActive] = useState(false);
  const [altxt, setAltTxt] = useState('');
  const [seoName, setSeoName] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [mediaType, setMediaType] = useState('');
  
  const openModal = (url,type,name,altxt) => {
    setAltTxt(altxt);
    setSeoName(name);
    setVideoUrl(url);
    setIsActive(true);
    setMediaType(type);
  };
  const closeModal = () => {
    setIsActive(false);
  };//Termina MOdal

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  
  const randomizedItems = items.sort(() => Math.random() - 0.5);
  const randomizedItemsVid = itemsVid.sort(() => Math.random() - 0.5);
  const displayedItems = randomizedItems.slice(startIndex, endIndex);
  const displayedItemsVid = randomizedItemsVid.slice(startIndex, endIndex);

  useEffect(() => {
    const carousel1 = document.querySelector('.slider-container');
    makeCarouselDraggable(carousel1);
    const carousel2 = document.querySelector('.carousel-container2');
    makeCarouselDraggable(carousel2);
  }, []);

  return (
    <>
    <div class="columns top"><img src="../img/top.png" alt="Placeholder image"/></div>

    <div class="columns grayBG contenedorSlide"> 
      <div class="column contSliderIzq cajaContIzq" >
      <h1 class="binevenidaTXT">Nuevos fondos animados para celular:</h1>
      <div className="spacer">
        <div  className='slider-container carousel-container1'>  
            {displayedItemsVid.map((itemsVid) => (
              <div className="cardGPT" key={itemsVid.id}>
                  <video autoplay="autoplay" playsinline muted loop>
                    <source src={itemsVid.urlSM}  alt={itemsVid.altxt} poster={itemsVid.poster} type="video/mp4"/>
                  </video>   
                  <div className="card-content is-flex  is-centered">
                  <button
                    className="button is-danger is-rounded btnCarrusel"
                    onClick={() => openModal(itemsVid.image, 'video', itemsVid.name)}
                  >Ver más</button>
                   </div>                 
              </div>
            ))}
        </div>
      </div>
      <Link to="/todos" ><a className="button is-info is-rounded btnCarrusel" >Todos los fondos animados para celular</a></Link> 
      </div>  {/*::::::::::::::::::::::::::::::::izq*/}     
      <div class="column contSliderIzq cajaContDer" >
        <h1 class="binevenidaTXT">Nuevos fondos de pantalla para celular:</h1>
        <div className="spacer">
          <div  className='slider-container carousel-container2'>  
            {displayedItems.map((item) => (
              <div className="cardGPT" key={item.id}>
                  <figure class="image is-1by2">
                    <img src={item.image} alt={item.altxt} />
                  </figure>
                  <div className="card-content is-flex  is-centered">
                  <button
                    className="button is-danger is-rounded btnCarrusel"
                    onClick={() => openModal(item.image, 'imagen', item.name)}
                  >Ver más</button>     
                  </div>              
              </div>
            ))}
          </div>
        </div>
        <Link to="/todaslasimagenes" ><a className="button is-info is-rounded btnCarrusel" >Ver todas los fondos</a></Link> 
      </div>
    </div>
    <div class="columns top margen-abajo"><img src="img/bottom.png" alt="Placeholder image"/></div>
    {isActive && (
        <Modal  isActive={isActive} closeModal={closeModal} videoUrl={videoUrl} mediaType={mediaType} seoName={seoName} altxt={altxt}/>
      )}
    </>
  );
};

export default FetchData;