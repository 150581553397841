import './App.css';
import React, { useState, } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import data from './db/fondo-pantalla.json';
import dataImg from './db/fondo-pantallaImg.json';
import FetchData from './components/FetchData';
import Pagination from './components/Pagination/Pagination';
import Filters from './components/Filters/Filters';
import Navbar from './components/NavBar/Navbar';
import HeroHome from './components/HeroHome/HeroHome';
import TodosImg from './components/PaginasImg/TodosImg';
import AnimalesImagenes from './components/PaginasImg/AnimalesImagenes';
import AnimeImagenes from './components/PaginasImg/AnimeImagenes';
import CarrosImagenes from './components/PaginasImg/CarrosImagenes';
import CartoonsImagenes from './components/PaginasImg/CartoonsImagenes';
import ComicsImagenes from './components/PaginasImg/ComicsImagenes';
import CuteImagenes from './components/PaginasImg/CuteImagenes';
import DinosImagenes from './components/PaginasImg/DinosImagenes';
import DeportesImagenes from './components/PaginasImg/DeportesImagenes';
import MemesImagenes from './components/PaginasImg/MemesImagenes';
import MoviesImagenes from './components/PaginasImg/MoviesImagenes';
import MusicaImagenes from './components/PaginasImg/MusicaImagenes';
import MiliciaImagenes from './components/PaginasImg/MiliciaImagenes';
import SeriesImagenes from './components/PaginasImg/SeriesImagenes';
import FrasesMoticacionImagenes from './components/PaginasImg/FrasesMoticacionImagenes';
import LugaresHermososImagenes from './components/PaginasImg/LugaresHermososImagenes';
import VideogamesImagenes from './components/PaginasImg/VideogamesImagenes';
import Todos from './components/PaginasVideo/Todos';
import Anime from './components/PaginasVideo/Anime';
import Animales from './components/PaginasVideo/Animales';
import Automoviles from './components/PaginasVideo/Automoviles';
import Cartoons from './components/PaginasVideo/Cartoons';
import Comics from './components/PaginasVideo/Comics';
import Cute from './components/PaginasVideo/Cute';
import Dinos from './components/PaginasVideo/Dinos';
import Deportes from './components/PaginasVideo/Deportes';
import Memes from './components/PaginasVideo/Memes';
import Movies from './components/PaginasVideo/Movies';
import Musica from './components/PaginasVideo/Musica';
import Milicia from './components/PaginasVideo/Milicia';
import Mexico from './components/PaginasVideo/Mexico';
import Series from './components/PaginasVideo/Series';
import SexyImagenes from './components/PaginasImg/SexyImagenes';
import MexicoImagenes from './components/PaginasImg/MexicoImagenes';
import FrasesMoticacion from './components/PaginasVideo/FrasesMoticacion';
import Lugareshermosos from './components/PaginasVideo/Lugareshermosos';
import Sexy from './components/PaginasVideo/Sexy';
import Videogames from './components/PaginasVideo/Videogames';
import Pie from './components/Pie/Pie';
import NotFound from './components/NotFound/NotFound';

function App(){
  return(

    <Router>    
    <Helmet>
       <title>Fondos de pantalla y fondos animados para celular</title>
       <meta name="description" content="Increibles fondos de pantalla para celular. fondos animados para celular de anime. fondos animados de autos ferrari mustang vw gti. fondos animados para celular de comics"/>
       <meta name="content" content="4k wallpaper para celular Increibles fondos de pantalla para teléfonos inteligentes. fondos de pantalla para celular gratis. Encuentra el fondo perfecto para mostrar tu estilo" />
       <meta name="keywords" content="fondos animados, descargar, teléfono, gratis, móvil, celular, fondo de pantalla"/>
    </Helmet>
      <div className='App'><Navbar /></div>
      <Routes>
        <Route path='/' element={<Home />} />       
        <Route path='/todos' element={<Todos />} />
        <Route path='/anime' element={<Anime />} />
        <Route path='/animales' element={<Animales />} />
        <Route path='/automoviles' element={<Automoviles />} />
        <Route path='/cartoons' element={<Cartoons />} />
        <Route path='/comics' element={<Comics />} />
        <Route path='/Cute_&_Adorable' element={<Cute />} />
        <Route path='/dinosaurios' element={<Dinos />} />
        <Route path='/deportes' element={<Deportes />} />
        <Route path='/memes' element={<Memes />} />
        <Route path='/peliculas' element={<Movies />} />
        <Route path='/musica' element={<Musica />} />
        <Route path='/milicia' element={<Milicia />} />
        <Route path='/mexico' element={<Mexico />} />
        <Route path='/series' element={<Series />} />
        <Route path='/sexy' element={<Sexy />} />
        <Route path='/videogames' element={<Videogames />} />
        <Route path='/lugareshermosos' element={<Lugareshermosos />} />
        <Route path='/motivacion' element={<FrasesMoticacion />} />   
        <Route path='/todaslasimagenes' element={<TodosImg />} />
        <Route path='/AnimeImagenes' element={<AnimeImagenes />} />
        <Route path='/AnimalesImagenes' element={<AnimalesImagenes />} />
        <Route path='/imagenescarros' element={<CarrosImagenes />} />
        <Route path='/imagenescartoons' element={<CartoonsImagenes />} />
        <Route path='/imagenescomics' element={<ComicsImagenes />} />
        <Route path='/imagenescute' element={<CuteImagenes />} />
        <Route path='/imagenesdinosaurios' element={<DinosImagenes />} />
        <Route path='/imagenesdeportes' element={<DeportesImagenes />} />
        <Route path='/imagenesmemes' element={<MemesImagenes />} />
        <Route path='/imagenespeliculas' element={<MoviesImagenes />} />
        <Route path='/imagenesmusica' element={<MusicaImagenes />} />
        <Route path='/imagenesmilicia' element={<MiliciaImagenes />} />
        <Route path='/imagenesmexico' element={<MexicoImagenes />} />
        <Route path='/imagenesseries' element={<SeriesImagenes />} />
        <Route path='/imagenessexys' element={<SexyImagenes />} />
        <Route path='/imagenesdelugareshermosos' element={<LugaresHermososImagenes />} />
        <Route path='/imagenesmotivacion' element={<FrasesMoticacionImagenes />} />
        <Route path='/imagenesvideogames' element={<VideogamesImagenes />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
    
  )
}

const Home = () => {
  
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsVid, setFilteredItemsVid] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
  <>
    <div className="App">
        
        <HeroHome />
        <main>
          <div>

             {/*<Search setFilteredItems={setFilteredItems} />*/}
            <div className='row'>

              <FetchData
                items={filteredItems.length > 0 ? filteredItems : dataImg.results}
                itemsVid={filteredItemsVid.length > 0 ? filteredItemsVid : data.resultsVid}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage} 
                
              />

            </div>
          </div>
        </main>
        <Pie />
      </div>
    </>
    
  );
}

export default App;
