import React, { useState } from "react";
import { Helmet } from 'react-helmet';

import { useSearch } from "../Search/UseSearch";
import itemsVidJson from "../../db/fondo-pantalla.json";
import Pagination from "../Pagination/Pagination";
import Modal from "../Modal/Modal";
import Pie from "../Pie/Pie";


export default function Todos() {
  const [isActive, setIsActive] = useState(false);
  const [seoName, setSeoName] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [mediaType, setMediaType] = useState('');
  const openModal = (url,type,name) => {
    setSeoName(name);
    setVideoUrl(url);
    setIsActive(true);
    setMediaType(type);
  };
  const closeModal = () => {
    setIsActive(false);
  };//Termina MOdal
  const itemsVid = itemsVidJson.resultsVid;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const [searchResults, handleSearch] = useSearch(itemsVid, "name");

  const indexOfLastItem = Math.min(currentPage * itemsPerPage, searchResults.length);
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);
  // Shuffle the currentItems array in a random order
  const shuffledItems = [...currentItems].sort(() => Math.random() - 0.5);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <><Helmet>
        <title>Fondos de Pantalla HD y Ultra 4k Wallpapers</title>
        <meta name="content" content="Las mejores imágenes para fondo de pantalla" />
        <meta name="keywords" content="fondos de pantalla, wallpapers, fondos de pantalla hd, 4k wallpapers  livewallpaper"/>
    </Helmet>
    <div className="columns mosaico">
      <div className="column is-1"></div>
      <div className="column is-10">
        <div className="columns contContol field">
          <h1 className="tituloBarra"><em>Todos los fondos de pantalla con movimiento</em></h1>
          <p className="control has-icons-left has-icons-right">
            <input className="input" type="text" placeholder="Buscar" onChange={handleSearch} />
            <span className="icon is-small is-left">
              <img src="../../img/search.svg" class="logoApp is-flex is-centered" alt="smartbackground.wtf" />
            </span>
          </p>
        </div>
        <div className="columns is-mobile is-multiline">
          {shuffledItems.map((item) => (
            <div key={item.id} className="column is-4-mobile is-2-desktop">
              <div className="card">
                <video autoPlay playsInline muted loop>
                  <source src={item.urlSM} alt={item.altxt} poster={item.poster} type="video/mp4" />
                </video>
                <div className="card-content is-flex  is-centered">
                  <button onClick={() => openModal(item.image, 'video', item.name)} id="showModal" className="button is-danger is-rounded btnCarrusel">
                    Ver mas
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={searchResults.length}
          paginate={paginate} />
      </div>
      {isActive && (
        <Modal isActive={isActive} closeModal={closeModal} videoUrl={videoUrl} mediaType={mediaType} seoName={seoName} />
      )}
    </div><Pie /></>
    
  );
}


